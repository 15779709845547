
::-webkit-scrollbar {
  width: 0px;
  background-color: #212529;
}
::-webkit-scrollbar-thumb {
  background-color: #212529;
}

::-webkit-scrollbar-track {
  background-color: #212529;
}